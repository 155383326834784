<template>
  <a-result status="404" title="404" sub-title="Запрашиваемая страница не найдена.">
    <template #extra>
      <a-button type="primary">
        <router-link :to="{ name: 'login' }" v-if="isGuest">Авторизоватся</router-link>
        <router-link :to="{ name: 'home' }" v-else>Главное страница</router-link>
      </a-button>
    </template>
  </a-result>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters('user', ['isGuest']),
};
</script>
